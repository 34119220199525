import { useContext, useEffect, useState } from 'react';
import { ButtonLink, InfoPopup } from '../styles';
import { FlexRow } from 'components/shared/flex-row';
import { Position } from 'devextreme-react/popup';
import { useScreenSize } from 'service/query-media';
import coordService from 'service/coord-service';
import ScrollView from 'devextreme-react/scroll-view';
import { OrderContext } from '../chartek';

const Info = () => {
  const { activeInfo, handleAction, order } = useContext(OrderContext);

  const [showInfo, setShowInfo] = useState(false);

  const [position, setPosition] = useState({
    my: 'right top',
    offset: { x: 67, y: 0 },
  });

  const [width, setWidth] = useState(100);

  useEffect(() => {
    if (activeInfo === order.id) {
      const coordPickupCol = coordService.getCoords(document.getElementById(`pickup-${order.id}`));
      setWidth(window.innerWidth - coordPickupCol.left + 10);
    }
  }, [activeInfo]);

  const screenSize = useScreenSize();

  useEffect(() => {
    if (activeInfo && activeInfo === order.id) {
      const myPos = { ...position };
      const coords = coordService.getCoords(document.getElementById(`btn--info--${order.id}`));

      const btnWidth = document.getElementById(`btn--info--${order.id}`)?.offsetWidth || 0;

      if (window.innerHeight - coords.top < 300) {
        myPos.my = 'right bottom';
        myPos.offset = {
          x: btnWidth * 2 - 13,
          y: -40,
        };
      } else {
        const height = Math.max(
          document.getElementById(`pickup-${order.id}`)?.offsetHeight || 0,
          document.getElementById(`delivery-${order.id}`)?.offsetHeight || 0,
        );

        myPos.offset = {
          x: btnWidth * 2 - 13,
          y: screenSize.isLarge ? height - 23 : height - 35,
        };

        myPos.my = 'right top';
      }

      setPosition(myPos);
    }
  }, [screenSize, activeInfo]);

  return (
    <>
      <ButtonLink
        icon="info"
        id={`btn--info--${order.id}`}
        onClick={() => {
          handleAction('info', order.id);
          setShowInfo(activeInfo === order.id ? false : true);
        }}
      />
      <InfoPopup
        onHiding={() => setShowInfo(false)}
        visible={showInfo}
        closeOnOutsideClick={true}
        showTitle={false}
        fullScreen={false}
        width={`${width}px`}
        height={200}
        focusStateEnabled={false}
        shading={false}
      >
        <Position of={`#btn--info--${order.id}`} my={position.my} offset={position.offset} />
        <ScrollView height={180}>
          <FlexRow style={{ padding: '10px' }} justifyContent="space-between">
            <div style={{ flex: 1, marginRight: '5px' }}>
              <div style={{ fontWeight: 500 }}>Address:</div>
              <div>
                <div>
                  <div>{order.from_address1}</div>
                  <div>{order.from_address2}</div>
                  <div>
                    {order.from_zipcode} {order.from_city} {order.from_country}
                  </div>
                  <div>{order.from_contact}</div>
                  <div>{order.from_tel}</div>
                  <div>{order.from_email}</div>
                </div>
              </div>
              <div style={{ fontWeight: 500, marginTop: '5px' }}>Note</div>
              <div>{order.from_note}</div>
            </div>
            <div style={{ paddingLeft: '14%', wordBreak: 'break-all', flex: 1, marginRight: '5px' }}>
              <div style={{ fontWeight: 500 }}>Address:</div>
              <div>
                <div>
                  <div>{order.to_address1}</div>
                  <div>{order.to_address2}</div>
                  <div>
                    {order.to_zipcode} {order.to_city} {order.to_country}
                  </div>
                  <div>{order.to_contact}</div>
                  <div>{order.to_tel}</div>
                  <div>{order.to_email}</div>
                </div>
              </div>
              <div style={{ fontWeight: 500, marginTop: '5px' }}>Note</div>
              <div>{order.to_note}</div>
            </div>
            <div style={{ wordBreak: 'break-word', flex: 1 }}>
              <div style={{ fontWeight: 500 }}>Comment:</div>
              <div>{order.comment || '-'}</div>
            </div>
          </FlexRow>
        </ScrollView>
      </InfoPopup>
    </>
  );
};

export default Info;
