import { InlinePopup, ButtonLink } from '../styles';
import ChangeStatusBtn from './status';
import { Position } from 'devextreme-react/popup';
import coordService from 'service/coord-service';
import { useContext, useState } from 'react';
import { OrderContext } from '../chartek';
import { useEffect } from 'react';

const Menu = () => {
  const { order, handleAction, activeMenu } = useContext(OrderContext);
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState('right bottom');
  const [offset, setOffset] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (activeMenu && activeMenu === order.id) {
      const coord = coordService.getCoords(document.getElementById(`btn--menu--${order.id}`));

      if (coord.top < window.innerHeight / 2) {
        setPosition('right top');
        setOffset({
          x: 35,
          y: 30,
        });
      } else {
        setPosition('right bottom');
        setOffset({
          x: 35,
          y: -30,
        });
      }
    }
  }, [activeMenu]);

  const handleShow = () => {
    setShow(activeMenu === order.id && show ? false : true);
    handleAction('menu', order.id);
  };

  return (
    <>
      <ButtonLink icon="overflow" id={`btn--menu--${order.id}`} onClick={handleShow}></ButtonLink>
      <InlinePopup
        onHiding={() => {
          setShow(false);
        }}
        visible={show}
        closeOnOutsideClick={true}
        showTitle={false}
        fullScreen={false}
        width={150}
        height={40}
        focusStateEnabled={false}
      >
        <Position of={`#btn--menu--${order.id}`} my={position} offset={offset} />
        <ChangeStatusBtn pos="right top" offset={{ x: -65, y: 50 }} />
      </InlinePopup>
    </>
  );
};

export default Menu;
