import { API_ENDPOINT } from 'constants/index';
import { Chartek } from '../model/chartek';
import downloadService from './download-service';
import { IHttpClient } from './http-client';

export class ChartekService {
  constructor(private httpClient: IHttpClient) {}

  getChartek(chartekNumber: string): Promise<Chartek> {
    return this.httpClient
      .get<{ data: Chartek }>(API_ENDPOINT.chartekSearch, { chartek_number: chartekNumber })
      .then((r) => r.data);
  }

  printReport(chartekId: number, reportType: string): void {
    return downloadService.download(
      this.httpClient.download(API_ENDPOINT.chartekReport, {
        charteks_id: chartekId,
        report_type: reportType,
        file_type: 'pdf',
        skip_empty_charteks: true,
        language: 'da',
      }),
      `${reportType}.pdf`,
    );
  }
}
