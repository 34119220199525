import { useContext, useEffect, useState } from 'react';
import { FlexRow } from 'components/shared/flex-row';
import { on } from 'devextreme/events';
import Info from './popup/info';
import Menu from './popup/menu';
import { OrderContext } from './chartek';
import { BoxShadowGap } from './styles';
import ColliList from './colli-list';
import ChangeStatusBtn from './popup/status';
import moment from 'moment';
import { ButtonLink, Col, StatusWrapper } from './styles';
import DocumentsButton from 'components/document-upload/documents-button';
import { PrintReportButton } from 'components/print/print-report-button';
import { IServicesContext, ServicesContext } from 'screens/chartek-view';

const OrderDetail = () => {
  const { order, reportTypes, activeMenu, activeInfo, swipeOrder, handleAction, activeColli } = useContext(
    OrderContext,
  );
  const { orderService } = useContext(ServicesContext) as IServicesContext;
  const [isActive, setActive] = useState<boolean | number>(false);

  const [id, setId] = useState('');

  useEffect(() => {
    if (activeMenu === order.id || swipeOrder === order.id || activeInfo === order.id) {
      setActive(order.id);
    } else if (activeMenu || swipeOrder || activeInfo) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, []);

  const actionButtons = () => {
    return (
      <FlexRow justifyContent="flex-end" alignItems="baseline" gap="5px">
        <DocumentsButton></DocumentsButton>
        <PrintReportButton
          reportTypes={reportTypes}
          reportSelected={(reportType: string) => {
            orderService.printReport(order.unique_id, reportType);
          }}
        ></PrintReportButton>
        <Info />
        <Menu />
      </FlexRow>
    );
  };

  useEffect(() => {
    setId(`order-row-${order.id}`);
  }, [order]);

  useEffect(() => {
    if (id) {
      const element: any = document.getElementById(id);
      on(element, 'dxswipeend', order.id, (data: any) => {
        const id = data?.data || data;
        const offset = data.targetOffset - data.offset;
        let res = offset < 0 ? 'left' : 'right';
        handleAction('swipe', res === 'right' ? id : false);
      });
    }
  }, [id]);

  return (
    <tbody className="dx-row" style={{ position: 'relative' }} id={id}>
      <tr className="main-row">
        {swipeOrder === order.id && (
          <td style={{ padding: 0, position: 'relative' }} colSpan={2}>
            <div
              style={{
                background: '#D8D8D8',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: '10px',
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                zIndex: 999,
              }}
            >
              <ChangeStatusBtn pos="center top" offset={{ x: 0, y: 0 }} radius={2} />
            </div>
          </td>
        )}
        <StatusWrapper bg={order.Status.color} rowSpan={2} className="order-id">
          <FlexRow alignItems="center">
            <span className="dx-icon-chevronnext"></span>
            <div>{order.sequence_no}</div>
          </FlexRow>
        </StatusWrapper>
        <td rowSpan={2}>
          <Col className="text-right pb-1">
            <div>{order.order_number_display}</div>
            <div>{order.id}</div>
            <div>{order.reference}</div>
          </Col>
        </td>
        <td>
          <Col>
            <FlexRow>
              <div>{order.total_collis_count}</div>
            </FlexRow>
            <ButtonLink icon="spindown" onClick={() => handleAction('colli', order.id)}></ButtonLink>
          </Col>
        </td>
        <td>
          <Col>
            <FlexRow justifyContent="space-between">
              <div>
                <i>KG</i>
              </div>
              <div>{order.total_collis_weight}</div>
            </FlexRow>
            <FlexRow justifyContent="space-between">
              <div>
                <i>M3</i>
              </div>
              <div>{order.total_collis_volume}</div>
            </FlexRow>
            <FlexRow justifyContent="space-between">
              <div>
                <i>LDM</i>
              </div>
              <div>{order.total_collis_ldm}</div>
            </FlexRow>
          </Col>
        </td>
        <td></td>
        <td>
          <Col id={`pickup-${order.id}`}>
            <div style={{ whiteSpace: 'break-spaces' }}>{order.from_name}</div>
            <div style={{ whiteSpace: 'break-spaces' }}>
              {order.from_address1} {order?.from_zipcode} {order.from_city}
            </div>
            <div>{moment(order.pickup_date).format('DD-MM-YYYY HH:mm ')}</div>
          </Col>
        </td>
        <td></td>
        <td>
          <Col id={`delivery-${order.id}`}>
            <div style={{ whiteSpace: 'break-spaces' }}>{order.to_name}</div>
            <div style={{ whiteSpace: 'break-spaces' }}>
              {order.to_address1} {order?.to_zipcode} {order?.to_city}
            </div>
            <div>{moment(order.delivery_date).format('DD-MM-YYYY HH:mm ')}</div>
          </Col>
        </td>
        {swipeOrder !== order.id && (
          <td>
            <Col id={`buttons-${order.id}`}>{actionButtons()}</Col>
          </td>
        )}
      </tr>
      {activeColli === order.id && (
        <tr>
          <td colSpan={6}>
            <div className="mb-2" style={{ marginTop: '-10px' }}>
              <ColliList collis={order.Collis} />
            </div>
          </td>
        </tr>
      )}
      <tr>
        <BoxShadowGap className={isActive === order.id ? 'active' : isActive ? 'blur' : ''} />
      </tr>
    </tbody>
  );
};

export default OrderDetail;
