import { Button, DropDownButton } from 'devextreme-react';
import './print-report-button.scss';

export const PrintReportButton = ({ reportTypes, reportSelected }: any) => {
  return (
    <DropDownButton
      icon="print"
      text="Print"
      dataSource={reportTypes}
      keyExpr="report_type"
      displayExpr="name"
      dropDownOptions={{
        width: 180,
        onContentReady: (e) => {
          console.log(e);
        },
        elementAttr: { class: 'print-report-dropdown-content' },
      }}
      itemRender={(data) => (
        <Button
          type="normal"
          text={data.name}
          onClick={(_e) => {
            reportSelected(data.report_type);
          }}
        />
      )}
    ></DropDownButton>
  );
};
