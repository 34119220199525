import { API_ENDPOINT } from 'constants/index';
import { DocumentType } from 'model/document-type';
import downloadService from './download-service';
import { IHttpClient } from './http-client';

export class OrderService {
  constructor(private httpClient: IHttpClient) {}

  updateStatus(id: number, status_id: number): Promise<any> {
    return this.httpClient
      .put<{ data: any }>(`${API_ENDPOINT.order}${id}`, { status_id: status_id })
      .then((r) => r);
  }

  uploadDocuments(orderUniqueId: number, files: File[], documentType: DocumentType): Promise<any> {
    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append(`file${index}`, files[index]);
    }

    formData.append('unique_id', orderUniqueId.toString());
    formData.append('allow_customer_viewing', documentType.show_to_web.toString());
    formData.append('allow_driver_viewing', documentType.show_to_driver.toString());
    formData.append('document_id', documentType.id.toString());

    return this.httpClient.post<{ data: any }>(`${API_ENDPOINT.order}`, formData);
  }

  printReport(uniqueId: number, reportType: string): void {
    return downloadService.download(
      this.httpClient.download(API_ENDPOINT.orderReport, {
        unique_id: uniqueId,
        report_type: reportType,
        file_type: 'pdf',
        language: 'da',
      }),
      `${reportType}.pdf`,
    );
  }
}
