import { stringify } from 'qs';

let baseURL = process.env.REACT_APP_API_BASE_URL || '';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const apiServer = urlParams.get('api_server');
if (apiServer) {
  baseURL = `https://${apiServer}.truckplanner.com/`;
}

export { baseURL };

export interface IHttpClient {
  get<T>(url: string, query: any): Promise<T>;
  post<T>(url: string, body: any, headers?: Headers): Promise<T>;
  put<T>(url: string, body: any, headers?: Headers): Promise<T>;
  download<T>(url: string, body: any, headers?: Headers): Promise<T>;
  request<T>(url: string, body: any, action: string, headers?: Headers): Promise<T>;
}

class HttpClient implements IHttpClient {
  get<T>(url: string, query: any, headers = new Headers()): Promise<T> {
    let requestURI = url;

    if (!url.startsWith('http') && !url.startsWith('//')) {
      if (url.startsWith('/')) {
        requestURI = baseURL + url;
      } else {
        requestURI = `${baseURL}/${url}`;
      }
    }

    const urlQuery = stringify(query, { addQueryPrefix: true, encode: false });

    return new Promise((resolve, reject) => {
      fetch(`${requestURI}${urlQuery}`, {
        headers,
      })
        .then((r) => {
          if (r.status !== 200) {
            reject(r);
          } else {
            resolve(r.json());
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  put<T>(url: string, body: any, headers = new Headers()): Promise<T> {
    return this.doRequest('PUT', url, body, headers);
  }

  post<T>(url: string, body: any, headers = new Headers()): Promise<T> {
    return this.doRequest('POST', url, body, headers);
  }

  download<T>(url: string, body: any, headers = new Headers()): Promise<T> {
    return this.doRequest('POST', url, body, headers, (r) => r.blob());
  }

  request<T>(url: string, body: any, action: string, headers?: Headers): Promise<T> {
    return this.doRequest(action, url, body, headers);
  }

  private doRequest<T>(
    httpMethod: string,
    url: string,
    body: any,
    headers = new Headers(),
    parseResponse?: (r: Response) => any,
  ): Promise<T> {
    let requestURI = url;

    if (!url.startsWith('http') && !url.startsWith('//')) {
      if (url.startsWith('/')) {
        requestURI = baseURL + url;
      } else {
        requestURI = `${baseURL}/${url}`;
      }
    }

    let bodyData = body;
    if (!(body instanceof FormData)) {
      headers.set('Content-Type', 'application/x-www-form-urlencoded');

      if (body) {
        bodyData = Object.keys(body)
          .map((key) => `${key}=${encodeURIComponent(body[key])}`)
          .join('&');
      }
    }

    return new Promise((resolve, reject) => {
      fetch(`${requestURI}`, {
        method: httpMethod,
        headers,
        body: bodyData,
      })
        .then((r) => {
          if (r.status !== 200) {
            reject(r);
          } else {
            resolve(parseResponse ? parseResponse(r) : r.json());
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

const httpClient = new HttpClient();
export default httpClient;
