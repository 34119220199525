import { FileUploader } from 'devextreme-react';
import styled from 'styled-components';

export const DocumentContainer = styled.div`
  height: 85%;
`;

export const File = styled.div`
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 25px;
  margin-bottom: 1rem;
  width: fit-content;
  box-shadow: 1px 1px 5px #888888;
  i {
    font-size: 20px;
    margin-right: 5px;
  }
  a {
    color: black;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  gap: 5px;
`;

export const UploadButton = styled(FileUploader)`
  width: 245px;
  .dx-button {
    background-color: rgb(250, 166, 26);
    color: white;
  }
`;
