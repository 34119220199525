import { OrderContext } from 'components/chartek/chartek';
import { FlexRow } from 'components/shared/flex-row';
import { Button, LoadIndicator, Popup, ScrollView } from 'devextreme-react';
import { Document } from 'model/document';
import { DocumentType } from 'model/document-type';
import { useContext, useState } from 'react';
import { IServicesContext, ServicesContext } from 'screens/chartek-view';
import { DocumentContainer, File, UploadButton } from './document-popup-styles';
import { DocumentTypeSelectPopup } from './document-type-select-popup';

const DocumentsPopup = ({ popupVisible, setPopupVisible, fileUploaded }: any) => {
  const { orderService } = useContext(ServicesContext) as IServicesContext;
  const { documentTypes, order } = useContext(OrderContext);
  const [documents, setDocuments] = useState(order.Documents);
  const [documentTypeSelectionPopupVisible, setDocumentTypeSelectionPopupVisible] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const documentGroupEls = [];

  for (const documentType of documentTypes) {
    const documentEls = [];
    const documentOfTypes = documents.filter((d: Document) => d.document_type_id === documentType.id);

    for (const document of documentOfTypes) {
      documentEls.push(
        <li>
          <File>
            <i className="dx-icon-file"></i>
            <a href={document.url} target="_blank" rel="noreferrer">
              {document.name}
            </a>
          </File>
        </li>,
      );
    }
    documentGroupEls.push(
      <div>
        <h2>{documentType.name}</h2>
        <ul>{documentEls.length > 0 ? documentEls : 'No files'}</ul>
      </div>,
    );
  }

  const uploadFiles = (documentType: DocumentType) => {
    setLoading(true);

    return orderService.uploadDocuments(order.unique_id, selectedFiles as File[], documentType).then(({ data }) => {
      setLoading(false);
      setDocuments(documents.concat([data]));
      fileUploaded(data);
    });
  };

  let popupContent = null;

  if (loading) {
    popupContent = (
      <FlexRow justifyContent="center" style={{ margin: '50px 0' }}>
        <LoadIndicator style={{ margin: 'auto' }} />
      </FlexRow>
    );
  } else {
    popupContent = (
      <div style={{ height: '100%' }}>
        <DocumentContainer>
          <ScrollView>{documentGroupEls}</ScrollView>
        </DocumentContainer>

        <FlexRow justifyContent="flex-end" alignItems="center">
          <UploadButton
            uploadMode="useForm"
            showFileList={false}
            selectButtonText="Upload"
            multiple={true}
            onValueChanged={(e) => {
              setDocumentTypeSelectionPopupVisible(true);
              setSelectedFiles(e.value as File[]);
            }}
          ></UploadButton>
          <Button style={{ height: '35px' }} text="Cancel" onClick={() => setPopupVisible(false)}></Button>
        </FlexRow>

        <DocumentTypeSelectPopup
          visible={documentTypeSelectionPopupVisible}
          setVisible={setDocumentTypeSelectionPopupVisible}
          documentTypeSelected={(documentType: DocumentType) => uploadFiles(documentType)}
        ></DocumentTypeSelectPopup>
      </div>
    );
  }

  return (
    <Popup
      title="Documents"
      width={600}
      height={600}
      visible={popupVisible}
      onHiding={() => setPopupVisible(false)}
      closeOnOutsideClick={true}
    >
      {popupContent}
    </Popup>
  );
};

export default DocumentsPopup;
