import DataGrid, { Column } from 'devextreme-react/data-grid';
import { Colli } from 'model/colli';

export interface CollisListType {
  collis: Colli[];
}

const ColliList = ({ collis }: CollisListType) => {
  return (
    <DataGrid dataSource={collis} className="no-color" showBorders={false} showColumnLines={false} showRowLines={false}>
      <Column dataField="mrk" caption="Mrk" dataType="string" width="15%"></Column>
      <Column
        dataField="label_count"
        caption="Count"
        dataType="string"
        cellRender={({ data }) => (
          <div>
            {data.count} - {data.code}
          </div>
        )}
        width="20%"
      ></Column>
      <Column
        dataField="description"
        caption="Description"
        dataType="string"
        cellRender={({ data }) => {
          return (
            <div>
              <div style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{data.description}</div>
            </div>
          );
        }}
        width="20%"
      ></Column>
      <Column dataField="weight" caption="KG" dataType="number" width="7.5%"></Column>
      <Column dataField="vol" caption="M3" dataType="number" width="7.5%"></Column>
      <Column dataField="ldm" caption="LDM" dataType="number" width="7.5%"></Column>
      <Column dataField="length" caption="L" dataType="number" width="7.5%"></Column>
      <Column dataField="width" caption="W" dataType="number" width="7.5%"></Column>
      <Column dataField="height" caption="H" dataType="number" width="7.5%"></Column>
    </DataGrid>
  );
};

export default ColliList;
