import authService from './authorization-service';
import httpClient, { IHttpClient } from './http-client';

export class AuthorizationProxyHttpClient implements IHttpClient {
  constructor(private jwtToken: string) {}

  get<T>(url: string, query: any): Promise<T> {
    return this.makeRequest(() => httpClient.get(url, query, this.setAuthorizationHeader()));
  }

  post<T>(url: string, body: any, headers = new Headers()): Promise<T> {
    return this.makeRequest(() => httpClient.post(url, body, this.setAuthorizationHeader(headers)));
  }

  put<T>(url: string, body: any, headers = new Headers()): Promise<T> {
    return this.makeRequest(() => httpClient.put(url, body, this.setAuthorizationHeader(headers)));
  }

  download<T>(url: string, body: any, headers = new Headers()): Promise<T> {
    return this.makeRequest(() => httpClient.download(url, body, this.setAuthorizationHeader(headers)));
  }

  request<T>(url: string, body: any, action: string, headers?: Headers): Promise<T> {
    return this.makeRequest(() => httpClient.request(url, body, action, this.setAuthorizationHeader(headers)));
  }

  private setAuthorizationHeader(headers = new Headers()): Headers {
    headers.set('Authorization', `Bearer ${this.jwtToken}`);
    return headers;
  }

  private makeRequest<T>(doRequest: () => Promise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      doRequest()
        .then((r) => resolve(r))
        .catch((r) => {
          if (r.status === 401) {
            authService.logout();
          }
          reject(r);
        });
    });
  }
}
