import styled from 'styled-components';

export const LoginForm = styled.form`
  margin: 1rem;
  .form-row {
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0.5rem 0;
  }
  .button-container {
    text-align: center;
    .dx-button {
      font-weight: 600;
      width: 8rem;
    }
  }
`;
