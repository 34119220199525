import { FlexRow } from 'components/shared/flex-row';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import { NativeEventInfo } from 'devextreme/events';
import { FormEvent, useContext } from 'react';
import dxTextBox from 'devextreme/ui/text_box';
import Validator from 'devextreme-react/validator';
import { RequiredRule } from 'devextreme-react/data-grid';
import { Chartek } from 'model/chartek';
import notify from 'devextreme/ui/notify';
import { StyledChartekForm } from './styles';
import { IServicesContext, ServicesContext } from './chartek-view';

const ChartekForm = ({ setChartek, setLoading, alignment }: any) => {
  let chartekNumber = '';
  const { chartekService } = useContext(ServicesContext) as IServicesContext;

  const justifyContent = alignment === 'center' ? 'center' : 'start';
  const alignItems = alignment === 'center' ? 'center' : 'baseline';
  const gap = alignment === 'center' ? '0' : '10px';
  const direction = alignment === 'center' ? 'column' : 'row';

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    chartekService
      .getChartek(chartekNumber)
      .then((chartek: Chartek) => {
        setChartek(chartek);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setChartek(null);
        notify('Link does not exist or is expired.', 'error');
      });
  };

  return (
    <StyledChartekForm onSubmit={onSubmit}>
      <FlexRow justifyContent={justifyContent} direction={direction} alignItems={alignItems} gap={gap}>
        <div className="mb-1">Please enter chartek number</div>
        <div className="mb-1">
          <TextBox
            width={300}
            onValueChanged={(e: NativeEventInfo<dxTextBox>) => {
              chartekNumber = e.component.option('text');
            }}
          >
            <Validator>
              <RequiredRule message="Please enter chartek number" />
            </Validator>
          </TextBox>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            id="button"
            icon="find"
            text="Search chartek"
            type="default"
            useSubmitBehavior={true}
            style={{ fontWeight: 600 }}
          />
        </div>
      </FlexRow>
    </StyledChartekForm>
  );
};

export default ChartekForm;
