import styled from 'styled-components';

interface IFlexRow {
  alignItems?: string;
  justifyContent?: string;
  flex?: string | number;
  wrap?: string;
  breakpoint?: string;
  direction?: string;
  alignSelf?: string;
  gap?: string;
}

export const FlexRow = styled.div<IFlexRow>`
  display: flex;
  align-self: ${(props) => props.alignSelf ?? 'inherit'};
  align-items: ${(props) => props.alignItems ?? 'inherit'};
  justify-content: ${(props) => props.justifyContent ?? 'inherit'};
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
  flex-direction: ${(props) => props.direction || 'row'};
  flex: 1;
  gap: ${(props) => props.gap || ''};
`;
