import { API_ENDPOINT } from 'constants/index';
import { AuthorizationProxyHttpClient } from './authorization-proxy-http-client';
import httpClient from './http-client';

class AuthorizationService {
  async login(
    username: string,
    password: string,
  ): Promise<{
    success: boolean;
    authProxyClient?: AuthorizationProxyHttpClient;
  }> {
    var result = await httpClient.post<{ success: boolean; jwt: string }>(API_ENDPOINT.auth, {
      username,
      password,
    });

    if (result.success) {
      localStorage.setItem('token', result.jwt);
    }

    return result.success ? { ...result, authProxyClient: new AuthorizationProxyHttpClient(result.jwt) } : result;
  }

  logout(): void {
    localStorage.removeItem('token');
    document.location.href = '/';
  }
}

const authService = new AuthorizationService();
export default authService;
