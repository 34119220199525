/* eslint-disable react-hooks/exhaustive-deps */
import LoadIndicator from 'devextreme-react/load-indicator';
import { createContext, useEffect, useState } from 'react';
import { Chartek } from '../model/chartek';
import { FlexRow } from 'components/shared/flex-row';
import { OrderStatus, Order } from 'model/order';
import Header from 'components/shared/header';
import Footer from 'components/shared/footer';
import ChartekForm from './chartek-form';
import ChartekComponent from 'components/chartek/chartek';
import ChartekInfo from 'components/chartek/chartek-info';
import LoginForm from 'components/login-form';
import { ChartekService } from 'service/chartek-service';
import httpClient from 'service/http-client';
import { AuthorizationProxyHttpClient } from 'service/authorization-proxy-http-client';
import { OrderService } from 'service/order-service';
import { PrintReportButton } from 'components/print/print-report-button';

export const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

export interface IServicesContext {
  chartekService: ChartekService;
  orderService: OrderService;
}

export const ChartekOrderContext = createContext<any>({});
export const ServicesContext = createContext<IServicesContext | null>(null);

const ChartekView = () => {
  const query = useQuery();
  const [chartek, setChartek] = useState<Chartek | null>(null);
  const [statuses, setStatuses] = useState<OrderStatus[] | null>(null);
  const [orders, setOrders] = useState<Order[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [authProxyClient, setAuthProxyClient] = useState<AuthorizationProxyHttpClient | null>(null);
  let authClient: AuthorizationProxyHttpClient | null = null;

  useEffect(() => {
    let token = localStorage.getItem('token');

    if (token) {
      authClient = new AuthorizationProxyHttpClient(token);
      setAuthProxyClient(authClient);
    }
  }, []);

  useEffect(() => {
    const link = query.get('chartek_number');
    authClient = authClient ?? authProxyClient;

    if (!loading && link && authClient) {
      const chartekService = new ChartekService(authClient);

      setLoading(true);
      chartekService
        .getChartek(link)
        .then((chartek: Chartek) => {
          setLoading(false);
          setChartek(chartek);
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
        });
    }
  }, [authProxyClient]);

  useEffect(() => {
    if (chartek) {
      if (chartek.folder) {
        setOrders(
          chartek.folder.Orders.sort((a: Order, b: Order) => {
            if (+a.sequence_no < +b.sequence_no) return -1;
            if (+a.sequence_no > +b.sequence_no) return 1;
            return 0;
          }),
        );
      }
      if (chartek.status) {
        setStatuses(chartek.status);
      }
    }
  }, [chartek]);

  const renderView = () => {
    const chartekService = new ChartekService(authProxyClient as AuthorizationProxyHttpClient);
    const orderService = new OrderService(authProxyClient as AuthorizationProxyHttpClient);

    if (error) {
      return (
        <div>
          <ServicesContext.Provider
            value={{
              chartekService: chartekService,
              orderService: orderService,
            }}
          >
            <ChartekForm setChartek={setChartek} setLoading={setLoading} alignment={'center'} />
            <FlexRow justifyContent="center">
              <h3>Link does not exist or is expired.</h3>
            </FlexRow>
          </ServicesContext.Provider>
        </div>
      );
    }
    if (loading) {
      return (
        <FlexRow justifyContent="center" style={{ margin: '50px 0' }}>
          <LoadIndicator />
        </FlexRow>
      );
    }
    if (orders && statuses && chartek) {
      const { Orders, ...rest } = chartek?.folder;
      return (
        <ServicesContext.Provider
          value={{
            chartekService: chartekService,
            orderService: orderService,
          }}
        >
          <ChartekOrderContext.Provider
            value={{
              reportTypes: chartek.customReport.filter((r) => r.print_on_order),
              setOrders,
              documentTypes: chartek.documentType,
              orders,
              statuses,
            }}
          >
            <div style={{ margin: '10px 0' }}>
              <FlexRow alignItems="baseline" gap="10px">
                <ChartekForm setChartek={setChartek} setLoading={setLoading} />
                <PrintReportButton
                  chartekId={rest.id}
                  reportTypes={chartek.customReport.filter((r) => r.print_on_folder)}
                  reportSelected={(reportType: string) => chartekService.printReport(rest.id, reportType)}
                ></PrintReportButton>
              </FlexRow>
            </div>

            <ChartekInfo info={rest} />
            <ChartekComponent />
          </ChartekOrderContext.Provider>
        </ServicesContext.Provider>
      );
    }

    if (authProxyClient) {
      return (
        <ServicesContext.Provider
          value={{
            chartekService: chartekService,
            orderService: orderService,
          }}
        >
          <ChartekForm setChartek={setChartek} setLoading={setLoading} alignment={'center'} />
        </ServicesContext.Provider>
      );
    }
    return <LoginForm setAuthProxyClient={setAuthProxyClient} setLoading={setLoading} />;
  };

  return (
    <>
      <Header setAuthProxyClient={setAuthProxyClient} isAuthorized={Boolean(authProxyClient)} />
      {renderView()}
      <Footer />
    </>
  );
};

export default ChartekView;
