import { OrderContext } from 'components/chartek/chartek';
import { Button } from 'devextreme-react';
import { useContext, useState } from 'react';
import DocumentsPopup from './documents-popup';

const DocumentsButton = () => {
  const { order } = useContext(OrderContext);
  const [documentCount, setDocumentCount] = useState(order.Documents.length);
  const [popupVisible, setPopupVisible] = useState(false);

  return (
    <div>
      <DocumentsPopup
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
        fileUploaded={() => {
          setDocumentCount(documentCount + 1);
        }}
      ></DocumentsPopup>
      <Button icon="file" text={`(${documentCount})`} onClick={() => setPopupVisible(true)}></Button>
    </div>
  );
};

export default DocumentsButton;
