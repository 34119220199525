import { OrderContext } from 'components/chartek/chartek';
import { FlexRow } from 'components/shared/flex-row';
import { Button, Popup, SelectBox, ValidationGroup } from 'devextreme-react';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { DocumentType } from 'model/document-type';
import { useContext } from 'react';
import { ButtonRow } from './document-popup-styles';

export const DocumentTypeSelectPopup = ({ visible, setVisible, documentTypeSelected }: any) => {
  const { documentTypes } = useContext(OrderContext);
  let selectedDocumentType: DocumentType;

  return (
    <Popup title="Select document type" width={400} height={200} visible={visible} onHiding={() => setVisible(false)}>
      <ValidationGroup>
        <FlexRow>
          <SelectBox
            style={{ flex: 1 }}
            dataSource={documentTypes}
            valueExpr="id"
            displayExpr="name"
            onSelectionChanged={(e) => (selectedDocumentType = e.selectedItem)}
          >
            <Validator>
              <RequiredRule message="Document type is required!" />
            </Validator>
          </SelectBox>
        </FlexRow>
        <ButtonRow>
          <Button
            text="OK"
            type="default"
            onClick={(e) => {
              const result = e.validationGroup.validate();

              if (!result.isValid) {
                return;
              }

              documentTypeSelected(selectedDocumentType);
              setVisible(false);
            }}
          ></Button>
          <Button text="Cancel" onClick={() => setVisible(false)}></Button>
        </ButtonRow>
      </ValidationGroup>
    </Popup>
  );
};
