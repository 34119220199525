import styled from 'styled-components';

export const light = {
  bg_color: '#fff',
  primary_color: '#555555',
  table: {
    header: {
      bg: '#00AEEF',
      color: '#fff',
    },
    body: {
      bg: '#fff',
      bgOdd: 'rgba(245, 245, 245, 0.9)',
    },
  },
};

export const dark = {
  table: {
    header: {
      bg: '#00AEEF',
      color: '#fff',
    },
  },
};

const AppStyled = styled.div`
  color: ${(props) => props.theme.primary_color};
  .no-color {
    .dx-datagrid-headers {
      background-color: ${(props) => props.theme.bg_color};
      color: ${(props) => props.theme.primary_color};
      border-bottom: none;
    }
    .dx-datagrid-table .dx-row > td {
      border-bottom: none;
    }
    .dx-datagrid-rowsview {
      border-top: none;
    }
    .dx-datagrid .dx-row > td {
      padding: 3px;
    }
  }
  .dx-datagrid-headers {
    background-color: ${(props) => props.theme.table.header.bg};
    color: ${(props) => props.theme.table.header.color};
    td {
      font-weight: 600;
    }
  }
  .text-right {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  .pb-1 {
    padding-bottom: 10px;
  }
  .mb-2 {
    margin-bottom: 20px;
  }
  .mb-1 {
    margin-bottom: 10px;
  }
  .mr-1 {
    margin-right: 10px;
  }
`;

export default AppStyled;
