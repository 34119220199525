const Footer = () => (
  <div style={{ right: '10px', bottom: '10px', position: 'absolute' }}>
    Powered by{' '}
    <a href="https://truckplanner.com/" target="_blank">
      www.truckplanner.com
    </a>
  </div>
);

export default Footer;
