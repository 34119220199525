import authService from 'service/authorization-service';
import { FlexRow } from './flex-row';
import { LogOutButton } from './header-styles';

const Header = ({ setAuthProxyClient, isAuthorized }: any) => {
  return (
    <FlexRow
      style={{ color: '#fff', background: '#FAA61A', padding: '10px', fontSize: '20px', fontWeight: 600 }}
      justifyContent="space-between"
      alignItems="center"
    >
      <div>Chartek search</div>
      {isAuthorized && (
        <LogOutButton
          text="Log out"
          onClick={() => {
            authService.logout();
            setAuthProxyClient(null);
          }}
          type="danger"
        ></LogOutButton>
      )}
    </FlexRow>
  );
};

export default Header;
