class DownloadService {
  download(downloadResult: Promise<Blob>, fileName: string) {
    downloadResult.then((r) => {
      const downloadUrl = URL.createObjectURL(r);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }
}

const downloadService = new DownloadService();
export default downloadService;
