import { Button, TextBox, Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/validator';
import { NativeEventInfo } from 'devextreme/events';
import dxTextBox from 'devextreme/ui/text_box';
import { FormEvent } from 'react';
import authService from 'service/authorization-service';
import { FlexRow } from './shared/flex-row';
import notify from 'devextreme/ui/notify';
import { LoginForm as StyledLoginForm } from './styles';

const LoginForm = ({ setAuthProxyClient, setLoading }: any) => {
  let username = '',
    password = '';

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    authService
      .login(username, password)
      .then((result) => {
        setLoading(false);

        if (result.success) {
          setAuthProxyClient(result.authProxyClient);
        }
      })
      .catch((_error) => {
        setLoading(false);
        notify('Incorrect username or password', 'error');
      });
  };

  return (
    <StyledLoginForm onSubmit={onSubmit}>
      <FlexRow className="form-row">
        <label>Username</label>
        <TextBox
          onValueChanged={(e: NativeEventInfo<dxTextBox>) => {
            username = e.component.option('text');
          }}
        >
          <Validator>
            <RequiredRule message="Please enter username" />
          </Validator>
        </TextBox>
      </FlexRow>
      <FlexRow className="form-row">
        <label>Password</label>
        <TextBox
          mode="password"
          onValueChanged={(e: NativeEventInfo<dxTextBox>) => {
            password = e.component.option('text');
          }}
        >
          <Validator>
            <RequiredRule message="Please enter password" />
          </Validator>
        </TextBox>
      </FlexRow>
      <div className="button-container">
        <Button text="Login" type="default" useSubmitBehavior={true} />
      </div>
    </StyledLoginForm>
  );
};
export default LoginForm;
