/* eslint-disable react-hooks/exhaustive-deps */
import AppStyled, { light } from './styles';
import themes from 'devextreme/ui/themes';
import { ThemeProvider } from 'styled-components';
import ChartekView from 'screens/chartek-view';

export const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

function App() {
  themes.current('generic.light');

  return (
    <ThemeProvider theme={light}>
      <AppStyled>
        <div />
        <ChartekView />
      </AppStyled>
    </ThemeProvider>
  );
}

export default App;
