import styled from 'styled-components';
import Button from 'devextreme-react/button';
import { FlexRow } from 'components/shared/flex-row';
import Popup from 'devextreme-react/popup';

export const PopupNoPadding = styled(Popup)`
  .dx-popup-content {
    padding: 0;
  }
`;
export const ButtonStyle = styled(Button)`
  min-height: 35px;
  line-height: 35px;
  margin: 0 5px;
  background: #f1f1f1;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  &.btn-active {
    .dx-button-content {
      padding-right: 25px;
      &:after {
        content: '';
        display: block;
        clear: both;
        height: 10px;
        width: 8px;
        border-top: 2px solid #fff;
        border-left: 2px solid #fff;
        transform: rotate(-136deg);
        position: absolute;
        top: 30%;
        right: 8px;
      }
    }
  }
  .dx-button-content {
    position: relative;
    line-height: 16px;
  }
`;

export const InfoPopup = styled(Popup)`
  .dx-overlay-content {
    background: #fff;
    border-top: none;
    border-radius: 0;
    box-shadow: rgb(216 216 216) 0px 4px 3px 0px;
  }
  .dx-popup-content {
    padding: 0 5px;
  }
`;

export const InlinePopup = styled(Popup)`
  &.dx-overlay-shader {
    background-color: transparent;
  }
  .dx-overlay-content {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  .dx-popup-content {
    padding: 0 5px;
  }
`;

export const Col = styled.div`
  position: relative;
  z-index: 9;
  padding-bottom: 10px;
`;

export const BoxShadowGap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 9px;
  right: 0;
  box-shadow: rgb(216 216 216) 0px 1px 4px 1px;
  border-left: 1px solid rgb(216 216 216);
  border-right: 1px solid rgb(216 216 216);
  &.blur:not(.active) {
    background-color: #fff;
    opacity: 0.7;
    z-index: 10;
  }
`;

type IStatusWrapper = {
  bg: string;
};

export const StatusWrapper = styled.td<IStatusWrapper>`
  position: relative;
  padding-left: 15px !important;
  div:first-child {
    &:after {
      background-color: ${(props) => props.bg};
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 10px;
      width: 10px;
    }
  }
`;
export const ChartekComponentWrapper = styled.div`
  padding: 10px;
  .dx-row.dx-freespace-row {
    display: none;
  }
`;

export const ButtonLink = styled(Button)`
  width: 40px;
  height: 40px;
  margin-top: 10px;
  border-width: 0px;
  background-color: transparent;
  box-shadow: none;
  .dx-button-content {
    padding: 0;
  }
  .dx-icon {
    font-size: 25px;
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
    line-height: 25px;
  }
  z-index: 999;
`;

export const AddressCell = styled(FlexRow)`
  flex-basis: 19%;
`;
